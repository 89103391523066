import React, { Suspense, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';

// Home
const Home = React.lazy(() => import("./components/pages/Home"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
const Homefour = React.lazy(() => import("./components/pages/Homefour"));
const Homefive = React.lazy(() => import("./components/pages/Homefive"));
// About
const About = React.lazy(() => import("./components/pages/About"));
// Partners
const Partners = React.lazy(() => import('./components/pages/Partners'))
// Product
const Products = React.lazy(() => import('./components/pages/Products'))
// Investors
const Investors = React.lazy(() => import('./components/pages/Investors'))
// Technical Operations
const Technical_Operation = React.lazy(() => import('./components/pages/TechnicalOperations'))
// Drug Safety
const Drug_Safety = React.lazy(() => import('./components/pages/DrugSafety'))
// Contact Us
const Contact_Us = React.lazy(() => import('./components/pages/Contact'))
// Shop
const Shopleft = React.lazy(() => import("./components/pages/Shopleft"));
const Shopright = React.lazy(() => import("./components/pages/Shopright"));
const Shopdetails = React.lazy(() => import("./components/pages/Shopdetails"));
// Career
const Career = React.lazy(() => import('./components/pages/Career'))
// Pages(Extra)
const Wishlist = React.lazy(() => import("./components/pages/Wishlist"));
const Cart = React.lazy(() => import("./components/pages/Cart"));
const Checkout = React.lazy(() => import("./components/pages/Checkout"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
// Blog
const Blogleft = React.lazy(() => import("./components/pages/Blogleft"));
const Blogright = React.lazy(() => import("./components/pages/Blogright"));
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
// Doctor
const Doctorgrid = React.lazy(() => import("./components/pages/Doctorgrid"));
const Doctorlist = React.lazy(() => import("./components/pages/Doctorlist"));
const Doctordetails = React.lazy(() => import("./components/pages/Doctordetails"));



// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})


function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          {/* Home */}
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/home-v2" component={Hometwo} />
          <Route exact path="/home-v3" component={Homethree} />
          <Route exact path="/home-v4" component={Homefour} />
          <Route exact path="/home-v5" component={Homefive} /> */}
          {/* About */}
          <Route exact path="/about-us" component={About} />
          {/* Partners */}
          <Route exact path="/partners" component={Partners} />
          {/* Products */}
          <Route exact path="/products" component={Products} />
          {/* Career */}
          <Route exact path="/career" component={Career} />
          {/* Investors */}
          <Route exact path="/investors" component={Investors} />
          {/* Technical Operation */}
          <Route exact path="/techOps" component={Technical_Operation} />
          {/* Drug Safety */}
          <Route exact path="/drugSafety" component={Drug_Safety} />
          {/* Contact Us */}
          <Route exact path="/contact-us" component={Contact_Us} />
          {/* Shop */}
          <Route exact path="/shop-left" component={Shopleft} />
          <Route exact path="/shop/cat/:catId" component={props => (<Shopleft {...props} key={window.location.pathname} />)} />
          <Route exact path="/shop/tag/:tagId" component={props => (<Shopleft {...props} key={window.location.pathname} />)} />
          <Route exact path="/shop/minPrice=:minPrice&maxPrice=:maxPrice" component={props => (<Shopleft {...props} key={window.location.pathname} />)} />
          <Route exact path="/shop-right" component={Shopright} />
          <Route exact path="/shop-details/:id" component={props => (<Shopdetails {...props} key={window.location.pathname} />)} />
          {/* Pages(Extra) */}
          <Route exact path="/wishlist" component={Wishlist} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/error-404" component={Error} />
          <Route exact path="/contact" component={Contact} />
          {/* Blog */}
          <Route exact path="/blog/cat/:catId" component={props => (<Blogleft {...props} key={window.location.pathname} />)} />
          <Route exact path="/blog/author/:authorId" component={props => (<Blogleft {...props} key={window.location.pathname} />)} />
          <Route exact path="/blog/tag/:tagId" component={props => (<Blogleft {...props} key={window.location.pathname} />)} />
          <Route exact path="/blog-left" component={Blogleft} />
          <Route exact path="/blog-right" component={Blogright} />
          <Route exact path="/blog-details/:id" component={props => (<Blogdetails {...props} key={window.location.pathname} />)} />
          {/* Doctor */}
          <Route exact path="/doctor/tag/:tagId" component={props => (<Doctorgrid {...props} key={window.location.pathname} />)} />
          <Route exact path="/doctor-grid" component={Doctorgrid} />
          <Route exact path="/doctor-list" component={Doctorlist} />
          <Route exact path="/doctor-details/:id" component={props => (<Doctordetails {...props} key={window.location.pathname} />)} />
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
